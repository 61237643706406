
import axios from 'axios';

import {
  defineComponent,
  ref,
  reactive,
  onMounted,
} from 'vue';

import type {
  EntriesPaginator,
  Feeds,
} from '@/types';

import {
  TRAFFIC_TYPES,
  TRAFFIC_TYPES_MAP,
  TRAFFIC_CATEGORIES,
  TRAFFIC_CATEGORIES_MAP,
  REGIONS,
  REGIONS_MAP,
  ENTRIES,
  FEED_STATUSES,
  FEED_STATUSES_MAP,
  ADSECURE_CHECK_STATUSES_MAP,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntries from '@/composable/useEntries';

import useStatisticsLink from '@/composable/useStatisticsLink';

export default defineComponent({
  setup() {
    const { activeUser } = useUser();

    const {
      entriesLoading: projectsLoading,
      entries: projects,
      fetchEntries: fetchProjects,
    } = useEntries();

    const { encodeStatisticsFilters } = useStatisticsLink();

    const feeds = ref<Feeds>(ENTRIES);

    const feedsFilters = reactive({
      project_id: null,
      name: null,
      traffic_type: null,
      traffic_category: null,
      region: null,
      status: null,
    });

    const fetchFeeds = async (feedsPaginator: EntriesPaginator): Promise<void> => {
      const response = await axios.post(
        '/api/feed/getFeedsPaginator',
        {
          ...feedsPaginator,
          ...feedsFilters,
        },
      );

      feeds.value = response.data as Feeds;
    };

    onMounted((): void => {
      Promise.all([
        activeUser.isSuperAdmin() ? fetchProjects('/api/project/getProjects') : Promise.resolve(),
      ]);
    });

    return {
      TRAFFIC_TYPES,
      TRAFFIC_TYPES_MAP,
      TRAFFIC_CATEGORIES,
      TRAFFIC_CATEGORIES_MAP,
      REGIONS,
      REGIONS_MAP,
      FEED_STATUSES,
      FEED_STATUSES_MAP,
      ADSECURE_CHECK_STATUSES_MAP,
      activeUser,
      projectsLoading,
      projects,
      encodeStatisticsFilters,
      feeds,
      feedsFilters,
      fetchFeeds,
    };
  },
});
